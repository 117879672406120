.partnerSection__row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  align-items: center;
  justify-items: center;
  gap: 20px;
}

.partnerSection__row img {
  max-width: 100%;
  min-width: 0;
}

@media (max-width: 540px) {
  .partnerSection__row {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
}
