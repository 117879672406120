.loading {
  background-color: var(--cShade);
  border: 1px solid var(--cShade);
  min-height: 631px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .loading {
    min-height: 523px;
  }
}

.calculator {
}

.calculator__tabs {
  height: 78px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 1024px) {
  .calculator__tabs {
    height: 50px;
  }
}

.calculator__tab {
  position: relative;
}

.calculator__tab input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.calculator__tab label {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(25em / 16);
  text-transform: uppercase;
  font-weight: bold;
  background-color: var(--cShade);
  border-top: 1px solid var(--cShade);
  border-bottom: 1px solid var(--cText);
  box-sizing: border-box;
  position: relative;
}

.calculator__tabLabel {
  font-weight: 400;
  font-family: var(--fontAccent);
  position: relative;
}

.calculator__tabLabel::after {
  position: absolute;
  content: '';
  display: block;
  width: 100%;
  background-color: currentColor;
  height: 3px;
  opacity: 0;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  bottom: -4px;
}

@media (max-width: 1024px) {
  .calculator__tab label {
    font-size: var(--sFontBody);
  }

  .calculator__tabLabel::after {
    height: 2px;
  }
}

.calculator__tab:first-child label {
  border-left: 1px solid var(--cShadeActive);
  border-right: 1px solid var(--cText);
}

.calculator__tab:last-child label {
  border-right: 1px solid var(--cShade);
}

.calculator__tab input:checked + label {
  background-color: var(--cShade);
  border-color: var(--cText);
  border-bottom: 1px solid #eaecf4;
}

.calculator__tab input:checked + label .calculator__tabLabel::after {
  opacity: 1;
}

.calculator__tab input:global(.focus-visible) + label {
  z-index: 1;
  box-shadow: var(--focusRingSecondary);
}

.calculator__body {
  display: flex;
  flex-direction: column;
  padding: 20px 40px 40px;
  background-color: var(--cShade);
  border-bottom: 1px solid var(--cText);
  border-left: 1px solid var(--cText);
  border-right: 1px solid var(--cText);
}

@media (max-width: 1024px) {
  .calculator__body {
    padding: 15px 25px 25px;
  }
}

.calculator__action {
  margin-top: 20px;
}

.calculator__card:not(:last-child) {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.calculator__cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.calculator__cardTitle {
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.8;
  font-family: var(--fontAccent);
}

.calculator__cardLabel {
  line-height: 1.8;
  color: var(--cTheme);
}

@media (max-width: 1024px) {
  .calculator__cardLabel,
  .calculator__cardTitle {
    font-size: var(--sFontTiny);
  }
}

.calculator__cardForm {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.calculator__cardForm > *:not(:last-child) {
  margin-right: 30px;
}

@media (max-width: 1024px) {
  .calculator__cardForm > *:not(:last-child) {
    margin-right: 20px;
  }
}

.calculator__input {
  height: 70px;
  font-family: var(--fontAccent);
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid var(--cText);
  margin: 0;
  padding: 0;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  font-size: calc(52rem / 16);
  appearance: none;
  background: none;
  outline: none;
  border-radius: 0;
  font-weight: 300;
  font-family: var(--fontAccent);
}

@media (max-width: 1024px) {
  .calculator__input {
    height: 45px;
    font-size: calc(33rem / 16);
  }
}

.calculator__input::-webkit-outer-spin-button,
.calculator__input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.calculator__input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.calculator__input:global(.focus-visible) {
  /*box-shadow: var(--focusRingSecondary);*/
  border-color: var(--cTheme);
}

.calculator__cardValue {
  height: 70px;
  font-family: var(--fontAccent);
  margin: 0;
  padding: 0;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  font-size: calc(52em / 16);
  display: flex;
  align-items: center;
  font-weight: 300;
  font-family: var(--fontAccent);
}

.calculator__cardValue span {
  flex-shrink: 0;
}

@media (max-width: 1024px) {
  .calculator__cardValue {
    height: 45px;
    font-size: calc(33rem / 16);
  }
}

.calculator__cardDescription {
  font-size: var(--sFontSmall);
  color: #999ca3;
}
