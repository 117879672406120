.calculatorDropdown {
  position: relative;
  display: inline-flex;
  font-size: calc(40rem / 16);
}

@media (max-width: 1024px) {
  .calculatorDropdown {
    font-size: calc(24rem / 16);
  }
}

.calculatorDropdown__trigger {
  height: 70px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--cBackgroundInverse);
  color: var(--cTextInverse);
  border: none;
  appearance: none;
  padding: 0 15px;
  font-family: var(--fontAccent);
  font-size: inherit;
  font-weight: 300;
  line-height: 1;
  cursor: pointer;
  outline: none;
  margin: 0;
}

.calculatorDropdown__trigger:global(.focus-visible) {
  box-shadow: var(--focusRingSecondary);
}

@media (max-width: 1024px) {
  .calculatorDropdown__trigger {
    height: 45px;
    padding: 0 10px;
  }
}

.calculatorDropdown__trigger svg {
  transition-duration: var(--tFast);
  transition-timing-function: var(--tfStandart);
  margin-left: 10px;
}

.calculatorDropdown_open .calculatorDropdown__trigger svg {
  transform: scaleY(-1);
}

.calculatorDropdown_open .calculatorDropdown__trigger {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.calculatorDropdown__triggerLabel {
  position: relative;
}

.calculatorDropdown__dropdown {
  position: absolute;
  top: 100%;
  width: 100%;
  display: none;
  background: var(--cBackgroundInverse);
  z-index: 1;
  padding-bottom: 10px;
}

.calculatorDropdown_open .calculatorDropdown__dropdown {
  display: flex;
  flex-direction: column;
}

.calculatorDropdown__dropdownButton {
  font: inherit;
  text-align: left;
  padding: 6px 15px;
  border: none;
  appearance: none;
  box-sizing: border-box;
  margin: 0;
  background: none;
  color: var(--cTextInverse);
  position: relative;
  cursor: pointer;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  outline: none;
  font-weight: 300;
  font-family: var(--fontAccent);
}

.calculatorDropdown__dropdownButton:global(.focus-visible) {
  box-shadow: var(--focusRingSecondary);
}

@media (max-width: 1024px) {
  .calculatorDropdown__dropdownButton {
    padding: 6px 10px;
  }
}

.calculatorDropdown__dropdownButton:hover {
  background: var(--cBackgroundInverse);
  background-color: rgba(255, 255, 255, 0.1);
}
